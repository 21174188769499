<template>
  <div>
    <v-dialog
      v-model="showModal"
      max-width="400"
    >
      <v-card
        class="mx-auto"
      >
        <v-toolbar
          color="#37474F"
          dark
        >
          <v-toolbar-title>
            Confirm Service Providers Structure ?
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p>This action will confirm and create this Service Providers group across your organization.</p>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="showModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="createConfirmed()"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card
      shaped
      color="#ECEFF1"
    >
      <br>
      <base-tree-view-without-actions
        :value="treeData"
        draggable
        droppable
        class="my-tree-view-style"
      />
      <v-col
        cols="12"
        class="text-right"
      >
        <v-btn
          class="mt-2"
          color="#FF3700"
          @click="create()"
        >
          create
        </v-btn>
      </v-col>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </div>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import { walkTreeData } from 'he-tree-vue';
  import Constants from 'src/constants';

  export default {
    name: 'GroupServiceProviders',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        treeData: [],
        loading: false,
        showModal: false,
        deleteMessage: '',
      };
    },
    computed: {
      createdStructure () {
        return this.$store.getters['serviceproviders/getCreatedServiceProvidersStructure'];
      },
      serviceProviders () {
        return this.$store.getters['serviceproviders/getServiceProviders'];
      },
      savedStructure () {
        return this.$store.getters['serviceproviders/getSavedServiceProvidersStructure'];
      },
    },
    async mounted () {
      this.loading = true;
      await this.$store.dispatch('serviceproviders/fetchServiceProviders');
      await this.$store.dispatch('serviceproviders/fetchSavedServiceProvidersStructure');
      this.createProvidersTreeList();
      this.loading = false;
    },
    methods: {
      createProvidersTreeList () {
        if (Object.keys(this.savedStructure).length <= 0) {
          const serviceProviders = this.serviceProviders;
          const structure = this.createdStructure;
          for (const serviceProvider of serviceProviders) {
            structure[0].children.push({
              id: serviceProvider.external_id,
              text: `${serviceProvider.first_name} ${serviceProvider.last_name} - ${serviceProvider.company.company_name}`,
            });
          }
          this.treeData = structure;
          return;
        }
          this.treeData = this.createdStructure;
          const availableServiceProviders = [];
            walkTreeData(this.treeData, (node) => {
              if (node.id !== undefined) {
                availableServiceProviders.push(node.id);
              }
            });
          const newServiceProviders = this.serviceProviders.filter(i => !availableServiceProviders.includes(i.external_id));
          for (const newServiceProvider of newServiceProviders) {
            this.treeData[0].children.push({
              id: newServiceProvider.external_id,
              text: `${newServiceProvider.first_name} ${newServiceProvider.last_name} - ${newServiceProvider.company.company_name}`,
            });
          }
      },
      create () {
        if (this.treeData.length > 1 || this.treeData[0].text !== 'All Service Providers') {
          this.$store.dispatch('alert/onAlert', {
            message: 'All nodes should come under "All service providers".',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.showModal = true;
      },
      async createConfirmed () {
        this.loading = true;
        await this.$store.dispatch('serviceproviders/groupServiceProviders', {
          group: this.treeData,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Service providers group created successfully',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.showModal = false;
          this.$router.push({ name: 'Service Providers' });
        }).finally(
          this.showModal = false,
          this.loading = false,
        );
      },
    },
  };
</script>
<style scoped>
.my-tree-view-style {
  margin-left: 30px;
}
</style>
